.videoStyle {
  width: 300%;
  height: 500px;
}

/* @media screen and (max-width: 499px) {
  .videoStyle {
    height: 350px;
  }
} */
